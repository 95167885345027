import { oldApi } from 'Config/path.js';
import { fetchGet } from 'Services/fetchService.js';

type TContact = {
    id: number,
    name: string,
    email: string,
    phone: string | null,
    system: number,
}

type TResponse = {
    firm: {
        id: number,
    },
    contragent: {
        id: number,
    }
    cid: number,
    id_outbox: number | null,
    contacts_more?: TContact[] | null,
    id_project?: number | null,
    aid?: number | null,
    date?: string | null,
}

export default async (taskId: number) => {
    const response: TResponse = await fetchGet(`${oldApi}/task_base_info.php?id=${taskId}`);

    return response;
};
