/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useReducer } from 'react';
import { FormProvider, useForm, useController } from 'react-hook-form';
import dayjs from 'dayjs';
import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';

import { IIFieldsVisibleConfigReqeust } from 'CommonTypes/Forms/FormsFieldsConfig';

import calculateDeadlineByLimitMeasure from 'Domain/ItServices/LimitMeasure/calculateDeadlineByLimitMeasure';
import TLimitMeasureIds from 'CommonTypes/ITServices/TLimitMeasureIds';
import IFunctionWithPath from 'CommonTypes/Functions/IFunctionWithPath';
import calculateDeadlineByFunctions from 'Domain/ItServices/LimitMeasure/calculateDeadlineByFunctions';
import getDataForForm from './services/getDataForForm';
import reducer from './models/reducer';

import FormView from './components/FormView';
import getCurrentValues from './models/getCurrentValues';
import IFormData from './types/IFormData';
import saveForm from './services/saveForm';
import updateTasksDeadline from './services/updateTasksDeadline';
import addSubprojectService from './services/addSubproject';

type TProps = {
    id: number,
} & IIFieldsVisibleConfigReqeust

function ServiceFunctionsView({ id, taskTypeId, formTypeId }: TProps) {
    const { success, error } = useEnqueueSnackbar();

    const [state, dispatch] = useReducer(
        reducer,
        {
            services: [],
            functions: [],
            serviceClass: {
                serviceClassId: 0,
                serviceClassName: '',
            },
            servicesByProject: [],
            taskDateCreate: dayjs(),
            deadline: dayjs(),
            startDate: dayjs(),
            subprojects: [],
            serviceClasses: [],
        },
    );

    const form = useForm<IFormData>({
        defaultValues: {
            serviceClass: null,
            services: null,
            functions: [],
            subprojects: [],
            planDuration: 0,
        },
    });

    const subprojectsController = useController({ name: 'subprojects', control: form.control });

    const onSubmit = useCallback(async (data: IFormData) => {
        const response = await saveForm(id, data);
        if (!response || !response?.success) {
            error('Во время сохранения возникла ошибка');
        }
    }, [error, id]);

    const formSubmit = useCallback(
        form.handleSubmit(onSubmit),
        [form, onSubmit],
    );

    const addSubproject = useCallback(async (subprojectName: string) => {
        const response = await addSubprojectService({ tagsName: subprojectName });
        if (response && response?.success && response?.tagId) {
            const newSubprojects = [
                ...state.subprojects,
                {
                    id: +response.tagId,
                    value: subprojectName,
                },
            ];
            dispatch({ type: 'setSubprojects', payload: newSubprojects });
            const currentValue = subprojectsController.field.value;
            const newValue = [
                ...currentValue,
                newSubprojects.slice(-1)[0],
            ];
            subprojectsController.field.onChange(newValue);
            formSubmit();
        }
    }, [formSubmit, state.subprojects, subprojectsController.field]);

    const setDeadline = async (limitation: number, limitMeasure: TLimitMeasureIds) => {
        const newDeadline = await calculateDeadlineByLimitMeasure(
            limitation,
            limitMeasure,
            state.taskDateCreate,
        );

        if (!newDeadline.isAfter(state.deadline)) {
            return false;
        }

        const result = await updateTasksDeadline(id, newDeadline, state.startDate);

        if (result?.success) {
            dispatch({
                type: 'setDeadline',
                payload: newDeadline,
            });
            success(`Срок задачи установлен: ${newDeadline.format('DD.MM.YYYY HH:mm:ss')}`);
        }

        return true;
    };

    const setDeadlineByFunctions = async (
        functionsLimitationsStr: string,
        currentFunctions: IFunctionWithPath[],
    ) => {
        if (functionsLimitationsStr?.length && currentFunctions?.length) {
            const maxDate = await calculateDeadlineByFunctions(
                functionsLimitationsStr,
                state.deadline,
                currentFunctions,
                state.taskDateCreate,
            );
            const result = await updateTasksDeadline(id, maxDate, state.startDate);

            if (result?.success) {
                dispatch({
                    type: 'setDeadline',
                    payload: maxDate,
                });
                success(`Срок задачи установлен: ${maxDate.format('DD.MM.YYYY HH:mm:ss')}`);
            }
        }
    };

    useEffect(() => {
        getDataForForm(id).then((data) => {
            const currentData = getCurrentValues(data);
            dispatch({
                type: 'init',
                payload: data,
            });
            form.reset(currentData);
        });
    }, [id, form]);

    const {
        services, functions, servicesByProject, subprojects, serviceClasses,
    } = state;

    return (
        <FormProvider {...form}>
            <FormView
                services={services}
                functions={functions}
                servicesByProject={servicesByProject}
                addSubproject={addSubproject}
                submitHandler={formSubmit}
                setDeadline={setDeadline}
                setDeadlineByFunctions={setDeadlineByFunctions}
                subprojects={subprojects}
                serviceClasses={serviceClasses}
                formTypeId={formTypeId}
                taskTypeId={taskTypeId}
            />
        </FormProvider>
    );
}

export default ServiceFunctionsView;
