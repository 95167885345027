import { initModule } from '../services/initModule.js';

export default {
    '/assets-list': {
        as: 'assets-list',
        uses: () => {
            import(
                /* webpackChunkName: "assets" */ '../../Assets/AssetsController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
