/* eslint-disable camelcase */
import React, { BaseSyntheticEvent } from 'react';
import dayjs from 'dayjs';

import di from 'Services/di.js';
import startTimer from './startTimer.js';
import drawTimer from './drawTimer.js';
import createTask from '../services/createTask.js';
import sendTask from '../services/sendTask.js';

import { IDataTask } from '../types/IDataTask';

interface IResult {
    id: number;
    success: boolean;
}

export default async (
    dataTask: IDataTask,
    eventId?: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentTimeRef?: React.MutableRefObject<number>, // TODO: использовать для телефонного звонка
) => {
    const eventsContoller = {
        create: async () => {
            const result = await createTask(dataTask);

            return result;
        },
        template: async () => {
            const taskDataToSend = {
                ...dataTask,
                taskName: dataTask.name,
                contacts: dataTask.contact,
                serviceClasses: dataTask.serviceClassId,
                projectId: dataTask.project,
                taskTypes: dataTask.taskTypeId,
                assigneeId: dataTask.assignee,
                deadline: dayjs.unix(dataTask.deadline).format('YYYY-MM-DD HH:mm:ss'),
                observersIds: dataTask.observers,
                template: true,
            };

            const result = await createTask(taskDataToSend);

            return result;
        },
        end: async () => {
            const { store } = di;
            const { reducerUser } = store.getState();
            const assignee = reducerUser.id;
            const currentTime = dayjs().unix();
            // const duration = ((currentTime - +currentTimeRef.current) / 60).toFixed(2);
            const result = await createTask(
                {
                    ...dataTask,
                    markcomplete: 1,
                    duration: 0,
                    assignee,
                    role: null,
                    date_complete: currentTime,
                },
            );

            return result;
        },
        favorite: async () => {
            const result = await createTask({ ...dataTask, favorite: 1 });

            return result;
        },
        start: async () => {
            const result: IResult = await createTask(dataTask);
            const { id, success } = result;
            if (!success) return false;
            if (id) startTimer(id);
            if (!id) drawTimer();
            return result;
        },
        send: async () => {
            const result = await createTask(dataTask);
            const { id } = result;

            if (dataTask.desc) {
                const store = di.store.getState();
                const { postId } = store.reducerUser;
                const dataSend = {
                    id_task: id,
                    text: dataTask.desc,
                    post_id: postId,
                    contacts: dataTask.observers,
                };

                sendTask(dataSend);
            }
            return result;
        },
    };

    const result = eventsContoller[(eventId as keyof typeof eventsContoller)]();

    return result;
};
