import React, { useMemo, useCallback } from 'react';
import {
    DataGridPremium as DataGrid,
    GridActionsColDef,
    GridCellParams,
    GridRowParams,
    GridRenderCellParams,
    GridActionsCellItem,
} from '@mui/x-data-grid-premium';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector, useDispatch } from 'react-redux';

import {
    useChangeRolesMatchingMutation,
    useDeleteRolesProjectShareMutation,
    useGetRolesSimpleSetQuery,
    useGetRolesUsersProjectsMatchingQuery,
    useUpdateProjectsGuestForUserMutation,
} from 'Endpoints/Roles/roles.api';
import { getSelectedProject, getUserIdForDelete } from '../utils/rolesMatrixProjectsSelectors';
import columns from '../utils/rolesByProjectsGridColumns';
import ProjectSelect from './ProjectSelect';
import { closeConfirmDeleteWindow, openConfirmDeleteWindow } from '../utils/rolesMatrixProjectsSlice';
import ConfirmDeleteRow from './ConfirmDeleteRow';
import BulletMarkMatrix from './BulletMarkMatrix';

type TResultObject = {
    [key: string]: number,
}

function RolesByProjectsGrid() {
    const dispatch = useDispatch();

    const { data: roles = [] } = useGetRolesSimpleSetQuery();
    const [deleteUserFromProject] = useDeleteRolesProjectShareMutation();
    const [changeRolesMatching] = useChangeRolesMatchingMutation();
    const [updateGuest] = useUpdateProjectsGuestForUserMutation();
    const selectedProjectId = useSelector(getSelectedProject);
    const userIdForDelete = useSelector(getUserIdForDelete);
    const { data = [] } = useGetRolesUsersProjectsMatchingQuery(
        { projectId: selectedProjectId },
        {
            skip: !selectedProjectId,
        },
    );

    const handleDoubleCellClick = useCallback((params: GridCellParams) => {
        const { field = '', id: userId = 0 } = params;
        if (field === 'guest') {
            updateGuest({ projectId: selectedProjectId, userId: +userId || 0 });
            return true;
        }

        const roleId = +field.replace('role', '');
        if (!Number.isFinite(roleId)) {
            console.log('Клик не по Роли'); // FIXME: Удалить этот console log
            return true;
        }

        changeRolesMatching({ roleId, projectId: selectedProjectId, userId: +userId || 0 });

        return true;
    }, [changeRolesMatching, selectedProjectId, updateGuest]);

    const handleClickDeleteButton = useCallback((userId: number) => {
        dispatch(openConfirmDeleteWindow({
            projectId: selectedProjectId,
            userId,
        }));
    }, [dispatch, selectedProjectId]);

    const columnsUpdated = useMemo(() => {
        const newColumns = [...columns];

        const actions: GridActionsColDef = {
            field: 'actions',
            type: 'actions',
            width: 40,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Удалить"
                    onClick={() => {
                        handleClickDeleteButton(+params.id);
                    }}
                />,
            ],
        };

        newColumns.push(actions);

        const resultObject: TResultObject = {};

        for (let index = 0; index < data.length; index++) {
            const user = data[index];
            const rolesParsed = (String(user?.roles || '') || '').split(',').filter(Boolean);

            for (let roleParsedIndex = 0; roleParsedIndex < rolesParsed.length; roleParsedIndex++) {
                const roleId = rolesParsed[roleParsedIndex];
                if (resultObject?.[roleId]) {
                    // eslint-disable-next-line no-plusplus
                    ++resultObject[roleId];
                } else {
                    resultObject[roleId] = 1;
                }
            }
        }

        const sortedRoles = [...roles].sort((a, b) => {
            const countA = resultObject?.[a.id] || 0;
            const countB = resultObject?.[b.id] || 0;

            return countB - countA;
        });

        for (let indexRole = 0; indexRole < sortedRoles.length; indexRole++) {
            const role = sortedRoles[indexRole];
            const colDef = {
                field: `role${role.id}`,
                headerName: role.value,
                width: 100,
                renderCell(params: GridRenderCellParams<0 | 1>) {
                    const value = Boolean(params.value);
                    return (
                        <BulletMarkMatrix value={value} />
                    );
                },
            };
            newColumns.push(colDef);
        }

        return newColumns;
    }, [data, handleClickDeleteButton, roles]);

    const handleCloseConfirmDelete = useCallback((result: boolean) => {
        if (result) {
            deleteUserFromProject({ projectId: selectedProjectId, userId: userIdForDelete });
        }

        dispatch(closeConfirmDeleteWindow());
    }, [deleteUserFromProject, dispatch, selectedProjectId, userIdForDelete]);

    return (
        <>
            <ProjectSelect />
            <DataGrid
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        textWrap: 'wrap',
                        lineHeight: '17px',
                        textOverflow: 'unset',
                        overflow: 'unset',
                    },
                }}
                rows={data}
                columns={columnsUpdated}
                getRowId={row => row.userId}
                onCellDoubleClick={handleDoubleCellClick}
                initialState={{ pinnedColumns: { left: ['userId', 'userName'] } }}
            />
            <ConfirmDeleteRow callback={handleCloseConfirmDelete} />
        </>
    );
}

export default RolesByProjectsGrid;
