import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridColumns, GridRowParams } from '@mui/x-data-grid';
import { Button, Icon } from '@mui/material';

import { isLinksTabActivated } from 'fsrc/Task/store/activeTabsFromTasksFormSelector';

import { ITasksLinksRequest, ITasksLinksResponse, ITasksLinksSetRequest } from 'CommonTypes/Tasks/TasksTypes/TasksLinks';
import { tasksApi, useGetTasksLinksQuery, useSetTasksLinkMutation } from 'Endpoints/Tasks/tasks.api';
import container from 'Services/container';
import renderDateForDataGridColumFormat from 'Utils/renderDateForDataGridColumFormat';
import openOldMessagePage from './models/openOldMessagePage.js';
import openOldOutboxMessagePage from './models/openOldOutboxMessagePage.js';

const columns: GridColumns<ITasksLinksResponse> = [
    {
        field: 'id',
        headerName: '№',
        width: 100,
    },
    {
        field: 'date',
        headerName: 'Дата',
        type: 'date',
        valueFormatter: renderDateForDataGridColumFormat,
    },
    {
        field: 'name',
        headerName: 'Наименование',
        flex: 1,
    },
    {
        field: 'start',
        headerName: 'Старт',
        type: 'date',
        valueFormatter: renderDateForDataGridColumFormat,
    },
    {
        field: 'finish',
        headerName: 'Финиш',
        type: 'date',
        valueFormatter: renderDateForDataGridColumFormat,
    },
    {
        field: 'assignee',
        headerName: 'Ответственный',
    },
    {
        field: 'typeName',
        headerName: 'Тип ссылки',
        width: 300,
    },
];

type TCallbackResult = {
    id: number;
    success: boolean;
}

function LinksTableDataGrid({ taskId }: ITasksLinksRequest) {
    const tabIsActivated = useSelector(isLinksTabActivated);

    const { data = [] } = useGetTasksLinksQuery(
        { taskId },
        {
            skip: !tabIsActivated,
        },
    );
    const dispatch = useDispatch();
    const [setTasksLink] = useSetTasksLinkMutation();

    const eventsMemo = useMemo(() => {
        const { events } = container;

        return events;
    }, []);

    const handleButtonAddLinkTaskClick = useCallback(() => {
        const parentItem = {
            id: taskId,
            name: '',
        };
        const taskData = {
            projectId: undefined,
        };

        eventsMemo.emit('tasks__formAddTask:addNewTask', {
            type: 'submissionTask',
            params: { data: taskData, parentItem },
            callback: (result: TCallbackResult) => {
                if (result?.success && result?.id) {
                    const linksData: ITasksLinksSetRequest = {
                        source: taskId,
                        target: +result.id,
                        type: 0,
                    };
                    setTasksLink(linksData);
                }
            },
        });
    }, [eventsMemo, setTasksLink, taskId]);

    const handleButtonAddLinkFromTargetTaskClick = useCallback(() => {
        const parentItem = {
            id: taskId,
            name: '',
        };
        const taskData = {
            projectId: undefined,
        };

        eventsMemo.emit('tasks__formAddTask:addNewTask', {
            type: 'submissionTask',
            params: { data: taskData, parentItem },
            callback: (result: TCallbackResult) => {
                if (result?.success && result?.id) {
                    const linksData: ITasksLinksSetRequest = {
                        source: +result.id,
                        target: taskId,
                        type: 0,
                    };
                    setTasksLink(linksData);
                }
            },
        });
    }, [eventsMemo, setTasksLink, taskId]);

    const handleButtonAddSubtaskClick = useCallback(() => {
        const parentItem = {
            id: taskId,
            name: '',
        };
        const taskData = {
            projectId: undefined,
        };

        eventsMemo.emit('tasks__formAddTask:addNewTask', {
            type: 'subTask',
            params: { data: taskData, parentItem },
            callback: (result: TCallbackResult) => {
                if (result?.success) {
                    dispatch(tasksApi.util.invalidateTags(['TasksLinksById']));
                }
            },
        });
    }, [dispatch, eventsMemo, taskId]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '10px',
                    marginBottom: '15px',
                }}
            >
                <Button variant="contained" onClick={handleButtonAddLinkTaskClick} startIcon={<Icon><i className="fa fa-plus" /></Icon>}>
                    Создать связанную задачу
                </Button>
                <Button variant="contained" onClick={handleButtonAddLinkFromTargetTaskClick} color="success" startIcon={<Icon><i className="fa fa-plus" /></Icon>}>
                    Создать ведущую задачу
                </Button>
                <Button variant="contained" onClick={handleButtonAddSubtaskClick} color="info" startIcon={<Icon><i className="fa fa-plus" /></Icon>}>
                    Создать подчинённую задачу
                </Button>
            </div>
            <DataGrid
                rows={data}
                columns={columns}
                onRowDoubleClick={(params: GridRowParams<ITasksLinksResponse>) => {
                    const { row: { id, type } } = params;
                    if (['task_link', 'task_parent', 'sub_task'].includes(type)) {
                        eventsMemo.emit('oldAppInbox:openTask', +id);
                        eventsMemo.emit('task__linksTable:closeTaskForm');
                    } else if (type === 'message') {
                        openOldMessagePage(id);
                    } else if (type === 'message_outbox') {
                        openOldOutboxMessagePage(id);
                    }
                }}
            />
        </>
    );
}

export default LinksTableDataGrid;
