import di from 'Services/di.js';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import defaultTasksConfig from 'Config/default/defaultTasksConfig';

import { IItServicesWithPath, IResponse } from 'CommonTypes/Tasks/QuickAddTaskForm/IResponse';

import getDefaultContact from './getDefaultContact';
import getDefaultServiceClasse from './getDefaultServiceClasse';
import getDefaultTaskType from './getDefaultTaskType';
import getDefaultAssignee from './getDefaultAssignee';
import getDefaultProject from './getDefaultProject';
import getSubdivision from './getSubdivision';
import getDefaultObservers from './getDefaultObservers';
import getDefaultFunctions from './getDefaultFunctions';

export default (
    data: IResponse,
    params: {
        data: { [key: string]: string | number; };
        parentItem?: { id: number; name: string; } | undefined;
        observersIds?: number[];
    } | undefined,
) => {
    const { store } = di;
    const { reducerUser } = store.getState();
    const { contactId, taskTypeId, id } = reducerUser;

    const contact = getDefaultContact(data.contacts, +(params?.data?.contact || contactId));
    const phone = String(params?.data?.phone || '') || contact?.phone || '';
    const orgstructure = getSubdivision(
        data.orgstructure,
        +(params?.data?.subdivisionId || 0) || contact?.subdivisionId || 0,
    );

    const serviceClasse = getDefaultServiceClasse(
        data.serviceClasses,
        +(params?.data?.serviceClasses || 0) || defaultTasksConfig.task.serviceClassId,
    );

    const project = getDefaultProject(
        data,
        contact,
        params?.data?.projectId as number || undefined,
    );

    const taskTypes = getDefaultTaskType(
        data.taskTypes,
        defaultTasksConfig.task.taskTypeId,
        +(params?.data?.taskTypes || 0) || project?.taskTypeId || taskTypeId,
    );

    const assignee = getDefaultAssignee(
        data.users,
        params?.data?.assigneeId as number || project?.mainAssignee || id,
    );

    const currentDayEnd = dayjs().endOf('day');
    const deadline = params?.data?.deadline ? dayjs(params?.data?.deadline) : currentDayEnd;
    const indate = currentDayEnd;

    const projectServicesIds = (project?.services || '').split(',').filter(el => el);

    let services: IItServicesWithPath | null = null;
    let role = null;

    if (params?.data?.it_service) {
        services = data.services.find(value => value.id === +params.data.it_service) || null;
    }

    if (projectServicesIds.length === 1) {
        if (!params?.data?.it_service) {
            services = data.services.find(value => value.id === +projectServicesIds[0]) || null;
        }
        if (services?.roleId) {
            role = data.roles.find(value => value.id === services?.roleId) || null;
        }
    }

    if (params?.data?.role) {
        role = data.roles.find(value => value.id === +params.data.role) || null;
    }

    const result = {
        taskName: params?.data?.taskName as string || '',
        desc: params?.data?.desc as string || '',
        contact,
        observers: params?.observersIds
            ? getDefaultObservers(data.contacts, params?.observersIds) : [],
        phone,
        serviceClasse,
        taskTypes,
        assignee: role ? null : assignee,
        project,
        services,
        role,
        deadline,
        indate,
        orgstructure,
        parentTaskId: null,
        functions: params?.data?.functionId
            ? getDefaultFunctions(data.functions, params?.data?.functionId as unknown as number[])
            : [],
        doctype: params?.data?.doctype
            ? data.docTypes.find(value => value.id === +params.data.doctype) || null
            : null,
        innumber: String(params?.data?.innumber || ''),
        intotal: Number(params?.data?.intotal || 0) || null,
    };

    return result;
};
