import React from 'react';

type TProps = {
    value: boolean;
}

function BulletMarkMatrix({ value }: TProps) {
    return (
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <div style={{
                borderRadius: '15px',
                width: value ? '15px' : '0',
                height: value ? '15px' : '0',
                backgroundColor: 'green',
            }}
            />
        </div>
    );
}

export default BulletMarkMatrix;
