import { RootState } from 'Utils/store/store';
import { ITasksTabsReducer } from '../types/TasksTabsState';

type State = RootState & ITasksTabsReducer;

export const getActivatedTabs = (state: State) => state.tasksTabsSliceReducer.activatedTabs;

export const isLinksTabActivated = (state: State) => state.tasksTabsSliceReducer
    .activatedTabs?.links;
export const isHistoryTabActivated = (state: State) => state.tasksTabsSliceReducer
    .activatedTabs?.history;
export const isTimesheetTabActivated = (state: State) => state.tasksTabsSliceReducer
    .activatedTabs?.timesheet;
